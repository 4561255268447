<template>
  <div>
    <el-form :label-position="'left'" label-width="100px" :model="form">
      <el-form-item label="请选择展览">
        <el-select
          v-model="form.meetingCode"
          clearable
          filterable
          placeholder="请选择展览"
          remote
          :remote-method="searchMeet"
          @change="chooseMeet"
        >
          <el-option
            v-for="item in meetData"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div v-if="form.meetingCode && !loading" class="target">
      <el-row :gutter="10" style="width: 100%">
        <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
          <vab-card
            class="target-card1"
            shadow="hover"
            skeleton
            :skeleton-rows="7"
            style="width: 100%; height: 305px"
          >
            <template #header>
              <vab-icon icon="user-3-line" />
              报名用户趋势分析
            </template>
            <vab-chart
              class="target-echart1"
              :init-option="initOptions"
              :option="option2"
              theme="vab-echarts-theme"
            />
          </vab-card>
        </el-col>
        <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
          <div class="order">
            <el-row :gutter="10" style="width: 100%">
              <el-col :lg="16" :md="24" :sm="24" :xl="12" :xs="12">
                <vab-card class="order-card1" shadow="hover">
                  <template #header>
                    <vab-icon icon="user-3-line" />
                    报名用户数据概览
                  </template>
                  <el-row class="order-card1-content">
                    <template
                      v-for="(item, index) in enrollUserData"
                      :key="index"
                    >
                      <el-col :span="item.colCount">
                        <p>{{ item.name }}</p>
                        <h1>
                          <vab-count
                            :decimals="item.countConfig.decimals"
                            :duration="item.countConfig.duration"
                            :end-val="item.countConfig.endVal"
                            :prefix="item.countConfig.prefix"
                            :separator="item.countConfig.separator"
                            :start-val="item.countConfig.startVal"
                            :suffix="item.countConfig.suffix"
                          />
                        </h1>
                      </el-col>
                    </template>
                  </el-row>
                </vab-card>
              </el-col>
              <el-col :lg="8" :md="24" :sm="24" :xl="12" :xs="12">
                <vab-card class="order-card2" shadow="hover">
                  <template #header>
                    <vab-icon icon="user-3-line" />
                    签到用户数据概览
                  </template>
                  <el-row class="order-card2-content">
                    <template
                      v-for="(item, index) in signUserData"
                      :key="index"
                    >
                      <el-col :span="item.colCount">
                        <p>{{ item.name }}</p>
                        <h1>
                          <vab-count
                            :decimals="item.countConfig.decimals"
                            :duration="item.countConfig.duration"
                            :end-val="item.countConfig.endVal"
                            :prefix="item.countConfig.prefix"
                            :separator="item.countConfig.separator"
                            :start-val="item.countConfig.startVal"
                            :suffix="item.countConfig.suffix"
                          />
                        </h1>
                      </el-col>
                    </template>
                  </el-row>
                </vab-card>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
          <div class="order">
            <el-row :gutter="10" style="width: 100%">
              <el-col :lg="16" :md="24" :sm="24" :xl="12" :xs="12">
                <vab-card class="order-card1" shadow="hover">
                  <template #header>
                    <vab-icon icon="user-3-line" />
                    用户互动数据概览
                  </template>
                  <el-row class="order-card1-content">
                    <template
                      v-for="(item, index) in interactionUserData"
                      :key="index"
                    >
                      <el-col :span="item.colCount">
                        <p>{{ item.name }}</p>
                        <h1>
                          <vab-count
                            :decimals="item.countConfig.decimals"
                            :duration="item.countConfig.duration"
                            :end-val="item.countConfig.endVal"
                            :prefix="item.countConfig.prefix"
                            :separator="item.countConfig.separator"
                            :start-val="item.countConfig.startVal"
                            :suffix="item.countConfig.suffix"
                          />
                        </h1>
                      </el-col>
                    </template>
                  </el-row>
                </vab-card>
              </el-col>
              <el-col :lg="8" :md="24" :sm="24" :xl="12" :xs="12">
                <vab-card class="order-card2" shadow="hover">
                  <template #header>
                    <vab-icon icon="user-3-line" />
                    嘉宾用户数据概览
                  </template>
                  <el-row class="order-card2-content">
                    <template v-for="(item, index) in guestData" :key="index">
                      <el-col :span="item.colCount">
                        <p>{{ item.name }}</p>
                        <h1>
                          <vab-count
                            :decimals="item.countConfig.decimals"
                            :duration="item.countConfig.duration"
                            :end-val="item.countConfig.endVal"
                            :prefix="item.countConfig.prefix"
                            :separator="item.countConfig.separator"
                            :start-val="item.countConfig.startVal"
                            :suffix="item.countConfig.suffix"
                          />
                        </h1>
                      </el-col>
                    </template>
                  </el-row>
                </vab-card>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <Loading v-show="loading == true" :rows="20" />
  </div>
</template>

<script>
  import { defineComponent, onMounted, reactive, toRefs } from 'vue'
  import VabChart from '@/extra/VabChart'
  import VabCount from '@/extra/VabCount'
  import _ from 'lodash'
  import { getPlateAllData, getPlatformData } from '@/api/workBench'
  import Loading from '@/components/loading/loading'

  export default defineComponent({
    components: {
      VabChart,
      VabCount,
      Loading,
    },
    setup() {
      const colorList = ['#9E87FF', '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF']
      const state = reactive({
        form: {
          meetingCode: '',
        },
        basicForm: {
          meetingType: 'EXHIBITION',
          likeName: '',
        },
        meetData: [],
        meetingChartVo: {},
        initOptions: {
          renderer: 'svg',
        },
        loading: false,
        // 报名用户数据
        enrollUserData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计报名用户数',
            colCount: 4,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '老用户数量',
            colCount: 4,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '%',
              separator: ',',
              duration: 3000,
            },
            name: '老用户占比',
            colCount: 4,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '新用户数量',
            colCount: 4,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '%',
              separator: ',',
              duration: 3000,
            },
            name: '新用户占比',
            colCount: 4,
          },
        ],
        // 签到用户数据
        signUserData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计签到用户数',
            colCount: 12,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '%',
              separator: ',',
              duration: 3000,
            },
            name: '签到占比',
            colCount: 12,
          },
        ],
        // 用户互动数据
        interactionUserData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '会议提问数量',
            colCount: 6,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '文件查看次数',
            colCount: 6,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '文件下载次数',
            colCount: 6,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '用户分享次数',
            colCount: 6,
          },
        ],
        // 嘉宾用户数据
        guestData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '会议嘉宾数量',
            colCount: 12,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '份',
              separator: ',',
              duration: 3000,
            },
            name: '嘉宾累计上传文件数量',
            colCount: 12,
          },
        ],
        option2: {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'z-index:1',
            // formatter: (params) => {
            //   console.log(params)
            // },
          },
          grid: {
            top: '4%',
            left: '2%',
            right: '2%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: [],
              axisLine: {
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                interval: 0,
                color: '#556677',
                fontSize: 10,
                margin: 5,
                showMinLabel: true,
                showMaxLabel: true,
              },
              axisPointer: {
                label: {
                  padding: [0, 0, 10, 0],
                  margin: 15,
                  fontSize: 12,
                  backgroundColor: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#33c0cd',
                      },
                      {
                        offset: 1,
                        color: '#33c0cd',
                      },
                    ],
                    global: false,
                  },
                },
              },
              boundaryGap: false,
            },
          ],
          yAxis: [
            {
              type: 'value',
              show: false,
            },
          ],
          series: [
            {
              name: '新用户报名数量',
              type: 'line',
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              tooltip: {},
              lineStyle: {
                width: 5,
                color: new VabChart.graphic.LinearGradient(1, 1, 0, 0, [
                  {
                    offset: 0,
                    color: '#73DD39',
                  },
                  {
                    offset: 1,
                    color: '#73DDFF',
                  },
                ]),
                shadowColor: 'rgba(115,221,255, 0.3)',
                shadowBlur: 10,
                shadowOffsetY: 20,
              },
              itemStyle: {
                color: colorList[1],
                borderColor: colorList[1],
              },
            },
            {
              name: '老用户报名数量',
              type: 'line',
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              tooltip: {},
              lineStyle: {
                width: 5,
                color: new VabChart.graphic.LinearGradient(1, 1, 0, 0, [
                  {
                    offset: 0,
                    color: '#c7254e',
                  },
                  {
                    offset: 1,
                    color: '#73DDFF',
                  },
                ]),
                shadowColor: 'rgba(115,221,255, 0.3)',
                shadowBlur: 10,
                shadowOffsetY: 20,
              },
              itemStyle: {
                color: colorList[1],
                borderColor: colorList[1],
              },
            },
          ],
        },
      
      })

      const fetchData = async () => {
        const { data } = await getPlateAllData(state.basicForm)
        state.meetData = data
      }

      onMounted(() => {
        fetchData()
      })

      const chooseMeet = (val) => {
        getData(val)
      }
      const getData = async (val) => {
        state.loading = true
        const { data } = await getPlatformData({
          exhibitionCode: val,
          meetingCode: '',
        })
        state.meetingChartVo = data.exhibitionChartVo
        // 处理X轴时间戳

        if (state.meetingChartVo.newUserChartVos) {
          const _dateArr = state.meetingChartVo.newUserChartVos.map((v) => {
            return v.date
          })
          const _newUserData = state.meetingChartVo.newUserChartVos.map((v) => {
            return v.count
          })
          const _oldUserData = state.meetingChartVo.oldUserChartVos.map((v) => {
            return v.count
          })
          state.option2.xAxis[0].data = _dateArr
          state.option2.xAxis[0].axisLabel.interval = Math.floor(
            state.meetingChartVo.newUserChartVos.length / 12
          )
          state.option2.series[0].data = _newUserData
          state.option2.series[1].data = _oldUserData
        } else {
          state.option2.xAxis[0].data = []
          state.option2.xAxis[0].axisLabel.interval = 0
          state.option2.series[0].data = []
          state.option2.series[1].data = []
        }

        // 报名用户数据
        state.enrollUserData[0].countConfig.endVal =
          state.meetingChartVo.attendCount
        state.enrollUserData[1].countConfig.endVal =
          state.meetingChartVo.attendOldUserCount
        state.enrollUserData[2].countConfig.endVal =
          state.meetingChartVo.attendOldUserProportion
        state.enrollUserData[3].countConfig.endVal =
          state.meetingChartVo.attendNewUserCount
        state.enrollUserData[4].countConfig.endVal =
          state.meetingChartVo.attendNewUserProportion

        // 签到用户数据概览
        state.signUserData[0].countConfig.endVal =
          state.meetingChartVo.signUserCount
        state.signUserData[1].countConfig.endVal =
          state.meetingChartVo.signUserProportion

        //  用户互动数据概览
        state.interactionUserData[0].countConfig.endVal =
          state.meetingChartVo.questionCount
        state.interactionUserData[1].countConfig.endVal =
          state.meetingChartVo.fileViewCount
        state.interactionUserData[2].countConfig.endVal =
          state.meetingChartVo.fileDownloadCount
        state.interactionUserData[3].countConfig.endVal =
          state.meetingChartVo.shareMeetingCount
        // 嘉宾用户数据概览
        state.guestData[0].countConfig.endVal = state.meetingChartVo.guestCount
        state.guestData[1].countConfig.endVal =
          state.meetingChartVo.guestFileCount

        setTimeout(() => {
          state.loading = false
        }, 800)
      }
      const searchMeet = (val) => {
        state.basicForm.likeName = val
        fetchData()
      }

      return {
        ...toRefs(state),
        chooseMeet,
        searchMeet,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .target {
    margin-bottom: $base-margin;
  }

  :deep() {
    .target-echart1 {
      width: 100%;
      height: 205px;
    }

    .target-echart2 {
      width: 100%;
      height: 205px;
    }
  }
  .order {
    display: flex;
    justify-content: space-between;
    &-card1 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }
    &-card2 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }
  }
</style>
